import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange, BaseResponse } from '~/sdk/shared';
import baseQuery from './base-query';
import { Entity as MeEntity, PutPayload } from '~/sdk/internal/v1/user/me';

// Define the endpoints here
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query<BaseExchange<MeEntity>, void>({
      query: () => ({
        url: '/internal/v1/user/me',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<MeEntity> => {
        return { data: response?.data } as BaseExchange<MeEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'User' }] : [];
      },
    }),
    updateUser: builder.mutation<BaseExchange<MeEntity>, BaseExchange<PutPayload>>({
      query: (payload) => ({
        url: '/internal/v1/user/me',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'User' }]
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
} = userApi;
