import axios, { AxiosResponse } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AuthTokenContract, LS_AUTH_KEY } from '~/context/AuthContext';
import { BASE_URL } from '~/config/api';

const baseQuery: BaseQueryFn<
  { url: string; method: string; body?: any },
  Record<string, unknown>,
  unknown
> = async ({ url, method, body }) => {
  const lsToken = window.localStorage.getItem(LS_AUTH_KEY);
  const token: AuthTokenContract | null = lsToken ? JSON.parse(lsToken) : null;

  const headers: { [key: string]: string } = {};

  if (token) {
    headers['Authorization'] = `${token.tokenType} ${token.accessToken}`;
  }

  try {
    const result: AxiosResponse = await axios({
      url: `${BASE_URL}${url}`,
      method,
      data: body,
      headers,
    });
    return { data: result.data };
  } catch (axiosError) {
    if (axios.isAxiosError(axiosError)) {
      return { error: axiosError.response?.data || axiosError.message };
    }
    return { error: 'An unknown error occurred.' };
  }
};

export default baseQuery;
