import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define an initial state
const initialState = {
  // Add any additional state you need to manage here
};

// Create the slice
const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {

  },
});

// Export the action creators and the reducer
export default invoicesSlice.reducer;
