import { AuthTokenContract, LS_AUTH_KEY } from '~/context/AuthContext'
import { BASE_URL } from '~/config/api'
import axios from 'axios'

const api = axios.create({ baseURL: BASE_URL })

api.interceptors.request.use((config) => {
  const lsToken = window.localStorage.getItem(LS_AUTH_KEY)
  const token: AuthTokenContract | null = lsToken ? JSON.parse(lsToken) : null

  if (config.headers && token) {
    config.headers['Authorization'] = `${token.tokenType} ${token.accessToken}`
  }

  return config
})

export default api
