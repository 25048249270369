
import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import { format, lastDayOfMonth } from 'date-fns'
import { zeroPad } from '~/utils/number'

export type Entity = {
  url: string,
}

export interface PostPayload {
  dateFrom?: string
  dateTo?: string
  month?: number
  year?: number
  file: 'yearly' | 'monthly' | 'accounting' | 'sold' | 'transaction' | 'business_loan'
  fileType: 'csv' | 'pdf'
  productType: 'BUSINESS_LOAN' | 'FACTORING' | 'QUICK_LOAN' | 'TRANSACTIONS',
}

function createDownloadQueryParams(data: PostPayload) {
  const query: Partial<PostPayload> = {}

  const { month, year, productType, file, ...rest } = data

  if (year && month) {
    query.dateFrom = `${year}${zeroPad(month, 2)}01`
    query.dateTo = format(lastDayOfMonth(new Date(year, month -1, 1)), 'yyyyMMdd')
  }

  // TODO: remove this when backend is fixed
  if (file === 'transaction') {
    query.productType = 'TRANSACTIONS'
  } else if (file === 'business_loan') {
    query.productType = 'BUSINESS_LOAN'
  } else if (file === 'accounting') {
    query.productType = 'QUICK_LOAN'
  } else if (file === 'sold') {
    query.productType = 'FACTORING'
  } else {
    query.productType = productType
  }

  query.file = file

  Object.entries(rest).forEach(([key, value]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    query[key as keyof PostPayload] = value.toString()
  })

  return query
}

export async function get(payload: BaseExchange<PostPayload>): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/accountings/download', { params: createDownloadQueryParams(payload.data)})
}
