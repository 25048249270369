/**
 * Set, get and remove data in session storage
 *
 * @param key The storage identifier
 */
function useSessionStorage<T>(key: string) {

  return {
    get: () => {
      let raw;
      if (typeof window !== 'undefined') {
        raw = sessionStorage.getItem(key);
      }

      return raw ? JSON.parse(raw) : null;
    },
    set: (value: T) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    remove: () => {
      sessionStorage.removeItem(key);
    },
  };
}

export default useSessionStorage;
