import { createSlice, createAsyncThunk, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { get, post, update, remove } from 'sdk/internal/v1/company/guarantors';
import { show as fetchGuarantor } from 'sdk/internal/v1/company/guarantors';
import { Entity as GuarantorsEntity, GuarantorCreatePayload } from 'sdk/internal/v1/company/guarantors';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { BaseResponse, BaseExchange } from '~/sdk/shared';
import { RootState } from '~/store';
import removeDuplicates from '~/utils/removeDuplicates';

const guarantorsAdapter = createEntityAdapter<GuarantorsEntity>({
  selectId: (guarantor) => guarantor.id,
});

export const guarantorsSelectors = guarantorsAdapter.getSelectors(
  (state: RootState) => state.guarantors
);

export const selectGuarantorBySSN = createSelector(
  [guarantorsSelectors.selectAll, (state: RootState, ssn: string) => ssn],
  (guarantors, ssn) => {
    return guarantors.find((guarantor) => guarantor.identityNumber.toString() === ssn);
  }
);

export const selectGuarantorById = createSelector([guarantorsSelectors.selectById], (guarantor) => guarantor);
export const selectAllGuarantors = createSelector([guarantorsSelectors.selectAll], (guarantor) => guarantor);

export const selectActiveGuarantors = createSelector(
  [guarantorsSelectors.selectAll],
  (guarantors) => guarantors.filter(guarantor => guarantor.active)
);

export const selectInactiveGuarantors = createSelector(
  [guarantorsSelectors.selectAll],
  (guarantors) => guarantors.filter(guarantor => !guarantor.active)
);

export type GuarantorMeta = {
  isActive: boolean,
  isNew: boolean,
  isSelected: boolean,
  isInit?: boolean
};

export type UpdateGuarantorMetaPayload = {
  id: number;
  meta: Partial<GuarantorMeta>;
};

export type GuarantorsState = {
  entities: Record<string, GuarantorsEntity | undefined>;
  meta: Record<string, GuarantorMeta>;
  ids: (string | number)[];
  selectedGuarantors: number[]
  selectedGuarantor: GuarantorsEntity | null;
  loading: boolean;
  error: string | undefined;
  status: 'loading' | 'init' | 'creating' | 'patching' | 'fetching' | 'ready'
};

const initialState: GuarantorsState = {
  ...guarantorsAdapter.getInitialState(),
  meta: {},
  status: 'init',
  selectedGuarantors: [],
  selectedGuarantor: null,
  loading: false,
  error: undefined,
};

export const fetchGuarantors = createAsyncThunk(
  'guarantors/fetchGuarantors',
  async () => {
    const response: BaseResponse<GuarantorsEntity[]> = await get();
    return removeDuplicates(response.data.data, 'identityNumber');
  }
);

export const postGuarantor = createAsyncThunk<
  GuarantorsEntity,
  GuarantorCreatePayload,
  { rejectValue: unknown; state: RootState }
>('guarantors/postGuarantors', async (payload: GuarantorCreatePayload, thunkAPI) => {

  const state = thunkAPI.getState() as RootState;

  // look for duplicate based on their id
  const existingGuarantor = selectGuarantorBySSN(
    state,
    payload.ssn
  );

  const meta = state.guarantors.meta;

  const guarantorIsVisible = (id: number) => {
    const guarantorMeta = meta[id];
    return guarantorMeta ? guarantorMeta.isInit || guarantorMeta.isNew : false;
  };

  if (existingGuarantor) {

    const isActive = existingGuarantor.active;

    // if this guarantor is an inactive guarantor, and not visible,
    // just make it appear like a new guarantor.
    if (!isActive && !guarantorIsVisible(existingGuarantor.id)) {
      thunkAPI.dispatch(updateGuarantorMeta({
        id : existingGuarantor.id, meta: { isNew: true, isSelected: true }
      }))

      return existingGuarantor;
    }

    // we're trying to make a duplicate guarantor.
    return thunkAPI.rejectWithValue({
      error: 'A guarantor with the same social security number already exists.',
    });
  }

  // now it's ok to make a new guarantor.
  const baseExchangePayload: BaseExchange<GuarantorCreatePayload> = { data: payload };
  const response: BaseResponse<GuarantorsEntity> = await post(baseExchangePayload);
  return response.data.data;
});

export const fetchGuarantorById = createAsyncThunk(
  'guarantors/fetchIfNotInStore',
  async (id: number, { getState, dispatch }) => {
    const state: RootState = getState() as RootState;
    const existingGuarantor = selectGuarantorById(state, id);

    if (!existingGuarantor) {
      // Fetch the guarantor from the API if it doesn't exist in the state
      const response: BaseResponse<GuarantorsEntity> = await fetchGuarantor(id);
      return response.data.data;
    }

    return existingGuarantor;
  }
);

export const patchGuarantor = createAsyncThunk<
  GuarantorsEntity,
  { id: number; payload: Partial<GuarantorCreatePayload> },
  { rejectValue: unknown }
>('guarantors/patchGuarantor', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const baseExchangePayload: BaseExchange<Partial<GuarantorCreatePayload>> = { data: payload };
    const response: BaseResponse<GuarantorsEntity> = await update(id, baseExchangePayload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteGuarantor = createAsyncThunk(
  'guarantors/deleteGuarantor',
  async (id: number) => {
    await remove(id);
    return id;
  }
);

const guarantorsSlice = createSlice({
  name: 'guarantors',
  initialState,
  reducers: {
    updateGuarantorMeta: (state, action: PayloadAction<UpdateGuarantorMetaPayload>) => {
      const { id, meta } = action.payload;
      state.meta[id] = { ...state.meta[id], ...meta };
    },
    selectGuarantor: (state, action: PayloadAction<number>) => {
      state.selectedGuarantors.push(action.payload);
    },
    deselectGuarantor: (state, action: PayloadAction<number>) => {
      state.selectedGuarantors = state.selectedGuarantors.filter(id => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuarantors.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchGuarantors.fulfilled, (state, action) => {
        guarantorsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.status = 'ready';

        action.payload.forEach((guarantor) => {
          state.meta[guarantor.id] = {
            ...state.meta[guarantor.id], ...{
              isActive: guarantor.active,
              isNew: false,
              isSelected: guarantor.active,
            }
          };
        });
      })
      .addCase(fetchGuarantors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = 'ready';
        state.meta = {};
      })
      .addCase(fetchGuarantorById.pending, (state) => {
        state.loading = true;
        state.status = 'fetching';
        state.error = undefined;
      })
      .addCase(fetchGuarantorById.fulfilled, (state, action) => {
        state.status = 'ready';
        state.selectedGuarantor = action.payload;
      })
      .addCase(fetchGuarantorById.rejected, (state, action) => {
        state.status = 'ready';
        state.error = action.error.message;
      })
      .addCase(postGuarantor.pending, (state) => {
        state.status = 'creating';
        state.error = undefined;
      })
      .addCase(postGuarantor.fulfilled, (state, action) => {
        guarantorsAdapter.addOne(state, action.payload);
        state.status = 'ready';
        state.meta[action.payload.id] = {
          ...state.meta[action.payload.id], ...{
            isNew: true,
            isSelected: true,
          }
        };
      })
      .addCase(postGuarantor.rejected, (state, action) => {
        state.status = 'ready';
        state.error = action.error.message;
      })
      .addCase(patchGuarantor.pending, (state) => {
        state.status = 'patching'
        state.error = undefined;
      })
      .addCase(patchGuarantor.fulfilled, (state, action) => {
        guarantorsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
        state.status = 'ready';
      })
      .addCase(patchGuarantor.rejected, (state, action) => {
        state.status = 'ready';
        state.error = action.error.message;
      })
      .addCase(deleteGuarantor.pending, (state) => {
        state.status = 'patching'
        state.error = undefined;
      })
      .addCase(deleteGuarantor.fulfilled, (state, action) => {
        state.status = 'ready';
        guarantorsAdapter.removeOne(state, action.payload);

        // remove from meta ass well
        delete state.meta[action.payload];
      })
      .addCase(deleteGuarantor.rejected, (state, action) => {
        state.status = 'ready';
        state.error = action.error.message;
      });
  }
});

export const { updateGuarantorMeta } = guarantorsSlice.actions;
export default guarantorsSlice.reducer;
