import { BaseExchange, EmptyBaseResponse, PaginatedBaseResponse } from '~/sdk/shared'
import Axios, { AxiosResponse } from 'axios'
import API from '~/sdk/client'

export enum DocumentType {
  BankAccountTransactions = 'BANK_ACCOUNT_TRANSACTIONS',
  SystemGeneratedInvoice = 'SYSTEM_GENERATED_INVOICE',
  AccountingDocOther = 'ACCOUNTING_DOC_OTHER',
  AccountingDocBsPl = 'ACCOUNTING_DOC_BS_PL',
  PdfWithFrontPage = 'PDF_WITH_FRONTPAGE',
  InvoiceDraft = 'INVOICE_DRAFT',
  AnnualReport = 'ANNUAL_REPORT',
  Peppol = 'PEPPOL',
  Pdf = 'PDF',
}

export interface Document {
  type: DocumentType
  filename: string
  url: string
  id: number
}

export async function get(req?: { page?: number, size?: number }): Promise<AxiosResponse<PaginatedBaseResponse<Document>>> {
  return await API.get(`internal/v1/company/documents?size=${req?.size || 25}&page=${req?.page || 1}`)
}

export async function destroy(req: { id: number }): Promise<EmptyBaseResponse> {
  return await API.delete(`internal/v1/company/documents/${req.id}`)
}

export async function upload(file: File, type: DocumentType): Promise<void> {
  const lastDot = file.name.lastIndexOf('.');
  const extension = file.name.substring(lastDot + 1);

  const { data: signedUrlResponse } = await API.post<
    BaseExchange<{ extension: string, type: string }>,
    BaseExchange<{ data: { url: string, source: string }}>
  >('internal/v1/company/documents/upload', {
    data: { extension, type }
  })

  await Axios.put(signedUrlResponse.data.url, file, {
    headers: { 'Content-Type': file.type }
  })

  await API.post<
    BaseExchange<{ type: DocumentType, source: string, filename: string }>
  >('internal/v1/company/documents', {
    data: {
      source: signedUrlResponse.data.source,
      filename: file.name,
      type: type
    }
  })
}
