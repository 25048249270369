import { configureStore } from '@reduxjs/toolkit';
import providersReducer from './providers/providersSlice';
import guarantorsReducer from './guarantors/guarantorsSlice';
import meReducer from './me/meSlice';
import applicationSubmitReducer from './applicationSubmit/applicationSubmitSlice';
import documentsReducer from './documents/documentsSlice';
import applicationReducer from './application/applicationSlice';
import invoicesReducer from './invoices/invoicesSlice';

import { invoicesApi } from '~/services/invoices.service';
import { customersApi } from '~/services/customers.service';
import { userApi } from '~/services/user.service';
import { productApi } from '~/services/product.service';
import { settingsApi } from '~/services/settings.service';
import { companyUsersApi } from '~/services/company.users.service';

const store = configureStore({
  reducer: {
    providers: providersReducer,
    guarantors: guarantorsReducer,
    me: meReducer,
    applicationsubmit: applicationSubmitReducer,
    documents: documentsReducer,
    application: applicationReducer,
    invoices: invoicesReducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [companyUsersApi.reducerPath]: companyUsersApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([
      invoicesApi.middleware,
      userApi.middleware,
      customersApi.middleware,
      productApi.middleware,
      settingsApi.middleware,
      companyUsersApi.middleware
    ])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
